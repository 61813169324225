.cont-proyecto{
    width: 25rem;
    height: 25rem;
    border-radius: 2rem;
    background-image: url('../assets/equipo_rhesident.jpg');
    background-position: center;
    background-size: cover;
    margin-left: 2rem;
    margin-right: 2rem;
}

.proyecto-overlay{
    width: 25rem;
    height: 25rem;
    border-radius: 2rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 2;
}

.descripcion-proceso-ind{
    display: none;
    opacity: 0;
    font-size: 1.4rem;
}

.cont-proyecto:hover .descripcion-proceso-ind{
    display: flex;
    animation: fade_in 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.cont-proyecto:hover .nombre-proceso-ind{
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fade_in 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.cont-proyecto:hover .proyecto-overlay{
    animation: color_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.cont-proyecto:hover{
    cursor: pointer;
}


@keyframes fade_in{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

