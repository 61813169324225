.hero-intenciones{
    background-size: cover;
    background-position: center;
}

.intenciones-detalle{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.intencion-cont{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
    height: 60vh;
}

.inclusion-img{
    z-index: 10;
    background-image: url('../assets/img_Inclusion.jpg');
    width: 60%;
    background-size: cover;
    background-position: center;
}

.cohesion-img{
    z-index: 10;
    background-image: url('../assets/img_Cohesion.jpeg');
    width: 60%;
    background-size: cover;
    background-position: center;
    margin-left: 4rem;
}

.comunidad-img{
    z-index: 10;
    background-image: url('../assets/img_Comunidad.JPG');
    width: 60%;
    background-size: cover;
    background-position: center;
    margin-right: 4rem;
}

.participacion-img{
    z-index: 10;
    background-image: url('../assets/img_Participacion.JPG');
    width: 60%;
    background-size: cover;
    background-position: center;
    margin-left: 6rem;
}

.intencion-texto{
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-size: 2rem;
}


.intencion-descripcion{
    display: none;
    opacity: 0;
    margin-bottom: 0;
    width: 40%;
}

.intencion-descripcion-sec{
    display: none;
}

.intencion-cont:hover .intencion-img{
    animation: image_hide 0.8s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

.intencion-cont:hover .intencion-texto{
    animation: slide_reveal 0.8s ease-in-out;
    animation-fill-mode: forwards;
}

.intencion-cont:hover .intencion-descripcion{
    animation: fade_reveal 1.5s ease-in;
    animation-fill-mode: forwards;
    display: flex;
}

.intencion-verde{
    background-color: var(--primario);
}

.cta-procesos{
    margin:20rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    box-sizing: border-box;
}

.cta-procesos h2{
    margin-bottom: 2rem;
}
.btn-intenciones-procesos{
    padding: 2rem 4rem;
    width: 100%;
    border-radius: 1rem;
    border: none;
    font-weight: 600;
    background-color: var(--secundario);
    font-family: Lato;
    cursor: pointer;
    color: var(--negro);
}

.btn-intenciones-procesos:hover{
    background-color: var(--primario);
    color: var(--blanco);
}

.btn-intenciones-procesos:hover .btn-intenciones-txt-secundario{
    display: flex;
}

.btn-intenciones-procesos:hover .btn-intenciones-txt-primario{
    display: none;
}


.btn-intenciones-txt-secundario{
    display: none;
    font-size: 2.4rem;
}

.btn-intenciones-txt-primario{
    font-size: 2.4rem;
}

.mision-vision{
    background-color: var(--primario);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20rem 8rem;
}

.mision-vision h2{
    margin-bottom: 4rem;
}

.mision, .vision, .objetivo{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.texto-mvo{
    width: 60%;
}

.valores{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10rem 0;
    align-items: center;
    margin-bottom: 10rem;
}

.valores-lista{
    margin-top: 10rem;
    list-style: none;
    text-align: center;
}

.valor{
    font-size: 2.4rem;
    color: var(--primario);
    margin-bottom: 2rem;
}

@keyframes slide_reveal {
    0%{width: 60%;}
    100%{width: 94.5%;}
}

@keyframes fade_reveal {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes image_hide {
    0%{width: 60%;}
    100%{width: 0%;}
}

@keyframes btn_grow{
    0%{
        width: 100%;
    }

    100%{
        width: auto;
    }
}


.proposito-evolutivo{
    background-image: url('../assets/img_PropositoEvolutivo.jpg');
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 768px) {
    .intencion-cont{
        flex-direction: column;
    }

    .inclusion-img{
        display: none;
    }

    .cohesion-img{
        display: none;
    }

    .comunidad-img{
        display: none;
    }

    .intencion-cont{
        align-items: center;
    }
    .participacion-img{
        display: none;
    }

    .intencion-cont:hover .intencion-texto{
        animation: none;
    }

    .intencion-texto{
        width: 90%;
    }

    .intencion-descripcion{
        display: flex;
    }

    .mision-vision{
        flex-direction: column;
        align-items: center;
    }

    .mision, .vision, .objetivo{
        width: 100%;
        margin-bottom: 2rem;
    }

}


@media only screen and (max-width: 576px) {
    .titulo-hero{
        width: 90%;
    }

    .descripcion-hero{
        width: 85%;
    }

    .intencion-descripcion{
        width: 100%;
    }
    
    .intencion-descripcion-sec{
        display: flex;
    }

    .int-hide{
        display: none !important;
    }

    .intencion-texto{
        flex-direction: column;
    }
}