.nav-movil{
    width: 100%;
    height: 110vh;
    box-sizing: border-box;
    background-color: var(--primario);
    z-index: 1000;
    padding: 4rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
}


.close-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    color: var(--blanco);
    cursor: pointer;
}

.nav-list-movil{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    text-align: center;
}

.submenu-title-movil{
    font-size: 3.4rem !important;
    color: var(--secundario) !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    margin-bottom: 0.8rem;
}

.nav-submenu-cont-movil{
    margin-top: 4rem;
}

.nav-submenu-cont-movil a{
    color: var(--blanco);
    font-size: 2.4rem;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.submenu-elements-movil{
    display: flex;
    flex-direction: column;
}