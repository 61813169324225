.cont-miembro{
    width: 30rem;
    background-image: url('../assets/huella_amarilla.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10rem;
    text-align: center;
    margin-left: 4rem;
    margin-right: 4rem;
    cursor: pointer;
}

.cont-miembro:hover .miembro-svg{
    transform: scale(1.1);
}

.miembro-svg{
    width: 80%;
    background-position:center;
}

.img-miembro image{
    display: flex;
    align-items: center;
    justify-content: center;
    filter: saturate(0);
}

.img-miembro-perfil{
    margin-inline: auto;
    width: 130%;
    height: auto;
}

.nombre-miembro{
    margin-top: 2rem;
}

@media only screen and (max-width: 1200px) {
    .miembro-svg{
        margin-top: -3rem;
    }
}

@media only screen and (max-width: 992px) {
    .miembro-svg{
        margin-top: -6rem;
    }
}

@media only screen and (max-width: 768px) {
    .miembro-svg{
        margin-top: -3rem;
    }
}

@media only screen and (max-width: 576px) {
    .miembro-svg{
        margin-top: -4rem;
    }
}