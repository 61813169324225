.cont-modal-responsive{
    width: 60rem;
    padding: 10rem 5rem;
    box-sizing: border-box;
}

.img-modal{
    width: 100%;
}

.icono-cerrar-modal{
    cursor: pointer;
    position: absolute;
    color: var(--negro);
    font-size: 3rem;
    margin-left: -4rem;
    margin-top: 2rem;
}