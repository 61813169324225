.hero-landing{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 8rem;
    box-sizing: border-box;
}
.main-svg{
    position: absolute;
    top: -1rem;
    z-index: -10;
    width: 100%;
}

.titulo-landing{
    margin-top: 18rem;
    text-align: center;
}
.subtitulo-landing{
    text-align: center;
    width: 75%;
    font-weight: 400;
}

.img-portada-landing{
    width: 100%;
}

.descripcion-hero{
    margin-top: 5rem;
    width: 40%;
    text-align: center;
    font-weight: 500;
    color: var(--blanco);
    font-size: 2rem;
}

.descripcion-hero-landing{
    margin-top: 5rem;
    width: 60%;
    text-align: center;
    font-weight: 500;
    color: var(--blanco);
    font-size: 2rem;
}

.hero-ctas{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    
}

.cta{
    background: #fff;
    border-radius: 5rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30rem;
    box-shadow: 8px 5px 15px #123a342a;
}

.icono-cta{
    width: 10rem;
    height: 10rem;
    margin-bottom: 4rem;
}

.img-cta{
    width: 100%;
}


.texto-cta{
    width: 80%;
    font-weight: 400;
    margin-bottom: 4rem;
}

.btn-cta{
    font-size: 2.4rem;
    font-family: 'Lato';
    color: var(--negro);
    font-weight: 600;
    padding: 1rem 1rem;
    border-radius: 1rem;
    background: var(--secundario);
    width: 70%;
    border: none;
    position: absolute;
    top: 85%;
    margin-left: -35%;
    cursor: pointer;
    align-items: center;
}

.btn-cta:hover{
    background-color: var(--primario);
    color: var(--blanco);
    animation: heartbeat 0.3s ease-in-out forwards;
}


.video-principal{
    padding: 0 25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nuestra-causa{
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
}

.color-overlay{
    background: rgba(22,68,83,0.7);
    padding: 10rem 0rem;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
}

.nuestra-causa h2{
    color: var(--blanco);
    margin-bottom: 4rem;
}


.nuestra-causa p{
    margin-inline: auto;
    margin-bottom: 1rem;
}
.causa-descripcion{
    font-size: 2rem;
    line-height: 3rem;
    width: 60%;
    margin-top: 4rem;
    color: var(--blanco);
    font-weight: 500;
}


.conoce{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20rem;
    box-sizing: border-box;
    overflow-x: hidden;
}

.huella-conoce{
    width: 40rem;
    position: absolute;
    margin-top: -10rem;
    left: 50%;
    margin-left: -22rem;
}

.subt-conoce{
    text-align: center;
    font-weight: 800;
    margin-top: 2rem;
}

.item-conoce{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-conoce{
    text-align: center;
    width: 40%;
    font-size: 1.6rem;
}

.path-conoce{
    stroke-dasharray: 1500;
    stroke-dashoffset: 1600;
    animation: dash 3s linear forwards 1;
}

.corazon-conoce{
    z-index: 10;
    margin-top: 2rem;
    background-image: url('../assets/corazon_conoce.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 5rem;
}

.corazon-conoce img{
    width: 38%;
    margin-left: 2rem;
}

.item-conoce p{
    margin-top: 2rem;
    text-align: center;
}

.line-conoce{
    position: absolute;
    margin-top: 65rem;
    z-index: -10;
    width: 1rem;
    height: 56rem;
    background-color: var(--secundario);
}

.line-conoce-2{
    position: absolute;
    margin-top: 159rem;
    z-index: -10;
    width: 1rem;
    height: 56rem;
    background-color: var(--secundario);
}

.line-conoce-3{
    position: absolute;
    margin-top: 251.5rem;
    z-index: -10;
    width: 1rem;
    height: 65rem;
    background-color: var(--secundario);
}



.nodes-cont-r{
    width: 22rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 20rem;
    z-index: 10;
    margin-bottom: 10rem;
    cursor: pointer;
}

.nodes-cont-l{
    width: 22rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 20rem;
    z-index: 10;
    margin-bottom: 10rem;
    cursor: pointer;
}


.node-conoce{
    width: 2rem;
    height: 2rem;
    border-radius: 25rem;
    background-color: var(--primario);
}

.node-texto{
    width: 70%;
    text-align: center;
    font-weight: 600;
}

.round-ctas{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 20rem;
}

.round-cta-cont{
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center;
}

.round-cta-cont h3{
    margin-bottom: 2rem;
}

.text-conoce{
    width: 30%;
}


.btn-round{
    width: 5rem;
    height: 5rem;
    background: var(--primario);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    cursor: pointer;
}

.btn-round:hover{
    transform: scale(1.1);
    background-color: var(--p-100);
    box-shadow: 2px 3px 5px #21647a76;
}

.btn-round img{
    width: 110%;
}

.contacto{
    width: 100%;
    padding: 8rem 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: var(--primario);
}

.newsletter-cont{
    width: 40%;
}

.newsletter-cont h2, .newsletter-cont p{
    margin-bottom: 4rem;
}

.info-contacto{
    width: 40%;
}

.info-contacto h2, .info-contacto p{
    margin-bottom: 4rem;
}

.input-newsletter-cont{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    background-color: var(--blanco);
    border-radius: 2rem;
    outline: 3px solid transparent;
}

.input-newsletter{
    font-size: 2rem;
    width: 100%;
    padding: .5rem 1rem;
    border: none;
    background-color: var(--blanco);
    font-family: Lato;
    color: var(--negro);
}


.input-newsletter:focus{
    outline:none;
}

.input-newsletter-cont:focus-within {
    outline: 3px solid var(--secundario);
}

.btn-newsletter{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secundario);
    border: none;
    border-radius: 0 2rem 2rem 0;
    cursor: pointer;
    width: 20%;
    height: 6rem;
}

.btn-newsletter:hover .icono-send-newsletter{
    animation: flip_180 0.8s;
    animation-timing-function: cubic-bezier(0.6, -0.5, 0.735, 0.045);
    animation-fill-mode: forwards;
}

.icono-send-newsletter{
    font-size: 3rem;
    color: var(--primario);
}

/* Input Newsletter Footer */

.input-newsletter-cont-s{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    background-color: var(--blanco);
    border-radius: 1rem;
    outline: 3px solid transparent;
}

.input-newsletter-s{
    font-size: 1.4rem;
    width: 100%;
    padding: .5rem 0rem;
    border: none;
    background-color: var(--blanco);
    font-family: Lato;
    color: var(--negro);
}



.input-newsletter-s:focus{
    outline:none;
}

.input-newsletter-cont-s:focus-within {
    outline: 3px solid var(--secundario);
}

.btn-newsletter-s{
    background-color: var(--primario);
    border: none;
    border-radius: 0 1rem 1rem 0;
    cursor: pointer;
    height: 4rem;
    width: 20%;
}

.btn-newsletter-s:hover .icono-send-newsletter{
    animation: flip_180 0.8s;
    animation-timing-function: cubic-bezier(0.6, -0.5, 0.735, 0.045);
    animation-fill-mode: forwards;
}

.icono-send-newsletter-s{
    font-size: 1.4rem;
    color: var(--blanco);
}

.mc-texto-s{
    font-size: 1.6rem !important;
    margin-top: 0.5rem;
}

 /*--------------------------------- */ 


.redes{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.redes a{
    text-decoration: none;
}

.redes a i{
    color: var(--secundario);
    font-size: 6rem;
}

.redes a i:hover{
    transform: scale(1.2);
    color: var(--blanco) !important;

}

.separador-contacto-vertical{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5%;
}

.separador-contacto-horizontal{
    display: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.circulo-separador{
    background-color: var(--blanco);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.linea-separador{
    width: .5rem;
    height: 100%;
    background-color: var(--secundario);
}

.linea-separador-horizontal{
    width: 100%;
    height: 0.5rem;
    background-color: var(--secundario);
}

.scrolldown-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 10rem;
    box-sizing: border-box;
}

.scrolldown-cont-small{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    height: 5rem;
    box-sizing: border-box;
}


.scroll-down-link {
    font-size: 4rem;
    opacity: 0.6;
	cursor:pointer;
	color: #FFF;
	text-align: center;
	text-decoration: none;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
	-webkit-animation: fade_move_down 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
	-moz-animation:    fade_move_down 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
	animation:         fade_move_down 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
	
}

.scroll-down-link-green{
    font-size: 4rem;
    opacity: 0.6;
	cursor:pointer;
	color: var(--primario);
	text-align: center;
	text-decoration: none;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
	-webkit-animation: fade_move_down 1s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate-reverse;
	-moz-animation:    fade_move_down 1s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate-reverse;
	animation:         fade_move_down 1s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate-reverse;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
    0%   { -webkit-transform:translate(0,-20px); }
    100% { -webkit-transform:translate(0,20px);}
  }
  
@-moz-keyframes fade_move_down {
0%   { -moz-transform:translate(0,-20px);}
100% { -moz-transform:translate(0,20px);}
}
@keyframes fade_move_down {
0%   { transform:translate(0,-20px); }
100% { transform:translate(0,20px); }
}



@keyframes flip_180 {
0%   { transform: rotateZ(0);}
100% { transform: rotateZ(360deg);}
}


.path {
stroke-dasharray: 1000;
stroke-dashoffset: 1000;
animation: dash 5s linear forwards;
}
.path-l{
stroke-dasharray: 1000;
stroke-dashoffset: 1000;
animation: dash 3s linear forwards;
}

.path-node-active{
animation: fadeIn_node 0.5s linear forwards;
}

.path-text-active {
animation: fadeIn_text 0.5s linear forwards;
}

.path-reverse{
stroke-dasharray: 1000;
stroke-dashoffset: 1000;
animation: dash 5s linear reverse;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeIn_text {
    from{fill: none; opacity: 0;}
    to{fill: var(--negro); opacity: 1;}
}

@keyframes fadeIn_node {
    from{fill: none; opacity: 0;}
    to{fill: var(--p-300); opacity: 1;}
}


.mc-texto{
    margin-top: 1rem !important;
    text-align: left !important;
    font-size: 2rem !important;
}

.mc-error{
    color: rgb(224, 100, 100) !important;
    font-size: 1.4rem;
}

.mc-exito{
    color: #F0E967 !important;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1400px) {
	.line-conoce{
        margin-top: 66.5rem;
        height: 57rem;
    }

    .line-conoce-2{
        margin-top: 160.5rem;
        height: 57rem;
    }

    .line-conoce-3{
        margin-top: 253rem;
        height: 66rem;
    }
}

@media only screen and (max-width: 1200px) {
	.main-svg{
        width: 110%;
    }

    .huella-conoce{
        width: 44rem;
        margin-top: -11rem;
    }

    .btn-round-voluntario img{
        width: 85%;
    }
}

@media only screen and (max-width: 992px) {
	.main-svg{
        width: 120%;
    }

    .huella-conoce{
        width: 43rem;
        margin-top: -15rem;
    }

    .line-conoce-3{
        height: 65rem;
    }

    .video-principal{
        padding: 0 20rem;
    }
}

@media only screen and (max-width: 768px) {
	.main-svg{
        width: 180%;
    }

    .subtitulo-landing{
        width: 95%;
    }

    .descripcion-hero{
        width: 85%;
    }

    .descripcion-hero-landing{
        width: 90%;
    }
    .hero-ctas{
        flex-direction: column;
        align-items: center;
        margin-top: 10rem;
    }

    .cta{
        width: 40rem;
        margin-bottom: 10rem;
        
    }

    .btn-cta{
        top: 63%;
    }

    .huella-conoce{
        width: 41rem;
        margin-top: -13rem;
    }


    .round-ctas{
        flex-direction: column;
    }

    .round-cta-cont{
        width: 70%;
        margin-bottom: 10rem;
    }

    .round-cta-cont h3{
        width: 60%;
    }

    .contacto{
        flex-direction: column;
        align-items: center;
    }

    .newsletter-cont{
        width: 80%;
    }

    .info-contacto{
        width: 80%;
    }

    .separador-contacto-horizontal{
        display: flex;
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    .separador-contacto-vertical{
        display: none;
    }

    .titulo-landing{
        margin-top: 14rem;
    }

    .video-principal{
        padding: 0 10rem;
    }
}

@media only screen and (max-width: 576px) {
    .main-svg{
        width: 270%;
    }

    .hero-landing{
        padding: 2rem 4rem;
    }

    .descripcion-hero{
        width: 98%;
    }

    .video-principal{
        padding: 0 5rem;
    }

    .titulo-landing{
        margin-top: 12rem;
    }

    .descripcion-hero-landing{
        margin-top: 2rem;
    }


    .huella-conoce{
        width: 44rem;
        margin-top: -12.5rem;
    }

    .newsletter-cont{
        width: 100%;
    }

    .info-contacto{
        width: 100%;
    }
    .cta{
        width: 36rem;
    }
}

@media only screen and (max-width: 560px) {
    .huella-conoce{
        width: 44rem;
        margin-top: -15rem;
    }
}


@media only screen and (max-width: 460px) {
    .huella-conoce{
        width: 44rem;
        margin-top: -16rem;
    }
    .line-conoce{
        margin-top: 68rem;
    }
    .line-conoce-2{
        margin-top: 165rem;
    }
    .line-conoce-3{
        margin-top: 260rem;
    }
}

@media only screen and (max-width: 400px) {
    .huella-conoce{
        width: 42rem;
        margin-top: -16rem;
    }

    .line-conoce{
        margin-top: 72rem;
    }
    .line-conoce-2{
        margin-top: 172rem;
    }
    .line-conoce-3{
        margin-top: 266rem;
    }
}

@media only screen and (max-width: 370px) {
    .main-svg{
        width: 300%;
    }

    .titulo-landing{
        font-size: 4.8rem;
        margin-top: 10rem;
    }

    .subtitulo-landing{
        font-size: 2.6rem;
    }
    .hero-landing{
        padding: 2rem 2rem;
    }
}