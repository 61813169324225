.hero-donaciones{
    background-size: cover;
    background-position: center;
}

.tipos-donaciones{
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.cont-tipo-donaciones{
    width: 33.33%;
    display: flex;
    flex-direction: column;
    padding: 8rem 4rem;
    align-items: center;
    text-align: center;

}
.texto-tipo-donacion{
    width: 80%;
}

.donar .btn-donaciones{
    background-color: var(--blanco);
    color: var(--negro);
}

.agenda-entrevista .btn-donaciones{
    background-color: var(--g-200);
    color: var(--blanco);
}

.agenda-entrevista i{
    color: var(--g-200);
}

.donar i{
    color: var(--blanco);
}


.donar{
    background-color: var(--primario);
}

.idea{
    background-color: var(--blanco);
}

.agenda-entrevista{
    background-color: var(--secundario);
}

.icono-donaciones{
    font-size: 10rem;
    margin-bottom: 2rem;
    color: var(--primario);
}

.mb4{
    margin-bottom: 4rem;
}
.cont-nombre-donaciones{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
    color: var(--negro);
}


.icono-pregunta{
    margin-left: 1rem;
    font-size: 2rem;
    cursor: pointer;
}

.btn-donaciones{
    width: 100%;
    font-family: Lato;
    background-color: var(--primario);
    color: var(--blanco);
    border: none;
    cursor: pointer;
    padding: 1.5rem 2rem;
    font-size: 2.4rem;
    border-radius: 1rem;
    font-weight: 600;
}

.btn-donaciones:hover{
    transform: scale(1.2);
}



.beneficiarios{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 10rem;
}

@media only screen and (max-width: 768px) {
    .tipos-donaciones{
        flex-direction: column;
    }

    .cont-tipo-donaciones{
        width: 100%;
        box-sizing: border-box;
    }
}

