.hero-voluntariado{
    background-size: cover;
    background-position: center;
}

.tipos-voluntariado{
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.cont-tipo-voluntariado{
    width: 33.33%;
    background: var(--blanco);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 4rem;
    z-index: 10;
}

.cont-tipo-voluntariado a{
    width: 70%;
}

.cont-tipo-voluntariado:hover{
    background-color: var(--primario);
}

.cont-voluntariado-active{
    background-color: var(--primario) !important;
}

.text-voluntariado-active{
    color:var(--blanco) !important;
}

.btn-voluntariado-active{
    background-color: var(--secundario) !important;
    color: var(--negro) !important;
}

.cont-tipo-voluntariado:hover .cont-nombre-voluntariado{
    color:var(--blanco);
}

.cont-tipo-voluntariado:hover .btn-ver-proyectos{
    color:var(--blanco);
}

.cont-tipo-voluntariado:hover .icono-voluntariado{
    color:var(--blanco);
}


.cont-tipo-voluntariado:hover .btn-voluntariado{
    background-color: var(--secundario);
    color: var(--negro);
}

.icono-voluntariado{
    font-size: 10rem;
    margin-bottom: 4rem;
    color: var(--p-300);
}
.cont-nombre-voluntariado{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
    color: var(--negro);
}


.icono-pregunta{
    margin-left: 1rem;
    font-size: 2rem;
}

.cont-i-explicacion:hover .explicacion-tipo{
    animation: fade_explicacion 0.5s linear forwards;
}

.btn-voluntariado{
    width: 100%;
    font-family: Lato;
    background-color: var(--primario);
    color: var(--blanco);
    border: none;
    cursor: pointer;
    padding: 1.5rem 0;
    font-size: 2.4rem;
    border-radius: 1rem;
    margin-bottom: 4rem;
    font-weight: 600;
}

.btn-voluntariado:hover{
    transform: scale(1.2);
}

.btn-ver-proyectos{
    border: none;
    background: rgba(0, 0, 0, 0);
    text-decoration: underline;
    font-weight: 600;
    color: var(--primario);
    font-size: 2rem;
    cursor: pointer;
}

.cta-voluntariado{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20rem 0;
    background-color: var(--blanco);
}

.btn-cta-voluntariado{
    width: 100%;
    font-family: Lato;
    background-color: var(--primario);
    color: var(--blanco);
    border: none;
    cursor: pointer;
    padding: 2rem 4rem;
    font-size: 2.4rem;
    border-radius: 1rem;
    font-weight: 600;
    margin-top: 4rem;
}

.btn-cta-voluntariado:hover{
    background-color: var(--secundario);
}

.btn-cta-voluntariado:hover .cta-voluntariado-t2{
    display: flex;
}
.btn-cta-voluntariado:hover .cta-voluntariado-t1{
    display: none;
}

.cta-voluntariado-t1{
    color: var(--blanco);
    font-size: 2.4rem;
}
.cta-voluntariado-t2{
    display: none;
    color: var(--negro);
    font-size: 2.4rem;
}

.video-voluntariado{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20rem 0;
    padding: 0 25rem;
    box-sizing: border-box;
    text-align: center;
}

.galeria-proyectos-voluntariado{
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.flecha-card-v{
    font-size: 2rem ;
    margin-top: 4rem;
}

.subt-center{
    text-align: center;
    width: 85%;
    margin-inline: auto;
}

.explicacion-tipo{
    opacity: 0;
    margin-left: -20rem;
    margin-top: -12rem;
    position: absolute;
    width: 30rem;
    padding: 0.5rem 2rem;
    background-color: var(--blanco);
    border-radius: 0.5rem;
    text-align: center;
}

.explicacion-tipo p{
    font-size: 1.4rem;
}

@keyframes fade_explicacion {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}



@media only screen and (max-width: 992px) {
    .video-voluntariado{
        padding: 0 15rem;
    }
}

@media only screen and (max-width: 768px) {
    .cont-tipo-voluntariado{
        padding: 4rem 2rem;
    }

    .video-voluntariado{
        padding: 0 10rem;
    }
}

@media only screen and (max-width: 576px) {
    .tipos-voluntariado{
        flex-direction: column;
    }

    .cont-tipo-voluntariado{
        width: 100%;
        box-sizing: border-box;
    }

    .video-voluntariado{
        padding: 0 5rem;
    }
}