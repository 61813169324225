.cont-podcast{
    margin-top: 10rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-right: 10rem;
    padding-left: 10rem;
    margin-bottom: 10rem;
    box-sizing: border-box;
    justify-content: space-between;
}
.header-podcast{
    margin-top: 15rem;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding: 0 10rem;
    width: 100%;
    margin-bottom: 10rem;
}
.img-podcast{
    width: 40%;
}
.podcast-acciones{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.interruptor-podcast{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 4rem;
    margin-top: 2rem;
}

.btn-youtube{
    width: 50%;
    background-color: var(--blanco);
    padding: 1.5rem;
    border-radius: 2rem 0 0 2rem;
    text-align: center;
    cursor: pointer;
}

.btn-youtube:hover{
    background-color: #FF0000;
    color: var(--blanco);
}

.btn-spotify:hover{
    background-color: #1ED760;
    color: var(--blanco);
}

.podcast-yt{
    background-color: #FF0000;
    color: var(--blanco);
}

.yt-i{
    color: var(--blanco) !important;
}

.podcast-spot{
    background-color: #1ED760 !important;
    color: var(--blanco);
}

.spot-i{
    color: var(--blanco);
}

.btn-youtube:hover i{
    color: var(--blanco);
}

.btn-spotify:hover i{
    color: var(--blanco);
}

.btn-youtube i{
    font-size: 6rem;
    color: var(--g-200);
}

.btn-spotify{
    width: 50%;
    background-color: var(--blanco);
    padding: 1.5rem;
    border-radius: 0 2rem 2rem 0;
    text-align: center;
    cursor: pointer;
}

.btn-spotify i{
    font-size: 6rem;
    color: var(--g-200);
}

.cont-siguenos{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.item-siguenos{
    font-size: 4rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 2rem;
    text-align: left;
    text-decoration: none;
}

.item-siguenos i{
    margin-right: 2rem;
}

.item-siguenos .fa-youtube{
    color: #FF0000;
}

.item-siguenos .fa-spotify{
    color: #1ED760;
}
.item-siguenos .fa-facebook{
    color: #0983ED;
}

.item-siguenos .fa-instagram{
    color: #E1306C;
}

.podcast-spotify{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vh;
    width: 80%;
}

.podcast-youtube{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vh;
    width: 70%;
}

.cont-frame{
    position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}


.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }




  @media only screen and (max-width: 768px) {

    .cont-podcast{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .podcast-acciones{
        width: 80%;
        align-items: center;
        text-align: center;
    }

    .cont-siguenos{
        display: none;
    }
    .podcast-spotify{
        width: 100%;
    }
    .podcast-youtube{
        width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    

      .cont-podcast{
          padding: 0 5rem;
      }
  }