.overlay-top{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    height: 12vh;
    padding: 0 10rem;
    background-color: rgba(0, 0, 0, 0.40);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(2px);
}

.btn-invita-donar{
    font-family: Lato;
    background-color: var(--primario);
    color: var(--blanco);
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
    font-size: 2rem;
    border-radius: 1rem;
}

.btn-invita-voluntariado{
    font-family: Lato;
    margin-left: 2rem;
    background-color: var(--secundario);
    color: var(--negro);
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
    font-size: 2rem;
    border-radius: 1rem;
}


.btn-invita-donar:hover{
    background-color: var(--blanco) !important;
    color: var(--negro);
    animation: heartbeat 0.3s ease-in-out forwards;
}

.btn-invita-voluntariado:hover{
    background-color: var(--blanco) !important;
    color: var(--negro);
    animation: heartbeat 0.3s ease-in-out forwards;
}

@keyframes heartbeat{
    0%{ transform: scale(1);}
    50%{ transform: scale(1.1);}
    60%{ transform: scale(1.08);}
    80%{ transform: scale(1.03);}
    100%{ transform: scale(1.1);}
}


@media only screen and (max-width: 768px) {
    .overlay-top{
        padding: 0 4rem;
    }
}
