.registro-contenido{
    margin-top: 15rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10rem;
}

.img-registro{
    width: 40%;
}

.cont-formulario-registro{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formulario-registro{
    width: 80%;
    display: flex;
    flex-direction: column;
}

.input-label{
    font-size: 1.8rem;
    color: var(--negro);
    margin-bottom: 0.4rem;
    font-weight: 600;
}

.input-gral{
    padding: 1rem;
    border-radius: 1.2rem;
    border: 2px solid var(--g-800);
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-family: Lato;
    resize: none;
    font-weight: 500;
    color: var(--g-300);
    background-color: rgba(255, 255, 255, 0);
}

.input-gral::placeholder{
    color: var(--g-500);
}


.input-gral:focus{
    outline: none !important;
    animation: focus_appear 0.3s ease-in-out forwards;
}
.select-form{
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    border: 2px solid var(--g-800);
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-family: Lato;
}

.btn-enviar{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--primario);
    color: var(--blanco);
    font-family: Lato;
    font-size: 2rem;
    margin-inline:auto;
    cursor: pointer;
    margin-top: 4rem;
}

.btn-enviar:hover{
    transform: scale(1.1);
    background-color: var(--p-100);
    box-shadow: 2px 3px 5px #21647a76;
}
.btn-enviar p{
    font-size: 2.4rem;
    margin-right: 2rem;
    font-weight: 600;
    
}

.input-archivo::-webkit-file-upload-button{
    background-color: var(--secundario);
    border: none;
    font-family: Lato;
    padding: 1rem 2rem;
    font-weight: 600;
    border-radius: 1rem;
}

.input-archivo::file-selector-button{
    background-color: var(--secundario);
    border: none;
    font-family: 'Lato';
    padding: 1rem 2rem;
    font-weight: 600;
    border-radius: 1rem;
}

.file-preview{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.preview-img{
    margin-top: 1rem;
    width: 20%;
    margin-right: 1rem;
}

.cont-formulario-agregar{
    width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
}


.file-preview-multi{
    display: flex;
    flex-direction: column;
}

.cont-galeria{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.warning-img{
    margin-bottom: 4rem;
    margin-top: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.warning-img i{
    font-size: 1.4rem;
    margin-right: 0.5rem;
    color: var(--g-400);
}

.txt-warning{
    font-size: 1.6rem;
    text-align: left;
    color: var(--g-500);
    font-weight: 500;
    
}

.btn-disabled{
    cursor: default;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--g-800);
    color: var(--g-400);
    font-family: Lato;
    font-size: 2rem;
    margin-inline:auto;
    margin-top: 4rem;
}

@keyframes focus_appear{
    0%{
        border: 2px solid var(--g-800);
    }
    100%{
        border: 2px solid var(--p-400);
    }
}

.dnone{
    display: none;
}


@media only screen and (max-width: 992px) {
    .registro-contenido{
        padding: 0 5rem;
    }

    .formulario-registro{
        width: 90%;
    }
}

@media only screen and (max-width: 768px) {
    .registro-contenido{
        margin-top: 4rem;
        flex-direction: column;
    }

    .img-registro{
        margin-bottom: 6rem;
    }

    .cont-formulario-registro{
        width: 70%;
    }
}

@media only screen and (max-width: 576px) {
    .img-registro{
        width: 70%;
    }
    .cont-formulario-registro{
        width: 95%;
    }
}