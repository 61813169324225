.hero-talleres{
    background-position: center;
    background-size: cover;
}

.talleres{
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10rem 0;
}

.talleres h2{
    margin-bottom: 5rem;
}

.talleres-activos, .talleres-proximos{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 10rem;
}