footer{
    width: 100%;
    background: var(--secundario);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 6rem 8rem 2rem 8rem;
    overflow-x: hidden;
}
.footer-main-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footer-details{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-footer{
    width: 60%;
    margin-top: -6rem;
    margin-bottom: -2rem;
}

.redes-footer{
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.redes-footer a i{
    font-size: 2.4rem;
    color: var(--primario);
}

.footer-links{
    width: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.footer-cont{
    width: 22rem;
    height: 15rem;
}
.footer-cont a{
    text-decoration: none;
    cursor: pointer;
}
.footer-subtitle{
    color: var(--p-200);
    font-weight: 800;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}
.footer-link{
    font-size: 1.6rem;
    color: var(--p-200);
    margin-bottom: 0.5rem;
}
.footer-link:hover{
    text-decoration: underline;
}


.footer-divider{
    margin-top: 1rem;
    margin-bottom: 2rem;
    height: 0.1rem;
    background-color: rgba(105, 105, 105, 0.2);
    width: 105%;
}

.footer-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-credits {
    text-align: start;
    color: var(--primario);
}

.footer-credits p{
    font-size: 1.6rem;
}


.footer-docs{
    text-align: end;
}

.footer-docs p{
    font-size: 1.6rem;
    color: var(--primario);
}

@media only screen and (max-width: 768px) {
    .footer-main-content{
        flex-direction: column;
    }

    .footer-details{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 6rem;
    }

    .logo-footer{
        width: 30%;
        object-fit: contain;
    }

    .contacto-footer{
        width: 30%;
    }

    .footer-links{
        width: 100%;
    }

    .footer-cont{
        width: 18rem;
    }
}

@media only screen and (max-width: 576px) {
    .footer-details{
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 6rem;
    }

    .logo-footer{
        width: 50%;
        margin-bottom: 1rem;
        margin-inline: auto;
    }

    .contacto-footer{
        margin-bottom: 3rem;
        text-align: center;
        align-self: center;
        align-items: center;
        margin-left: 0;
        width: 20rem;
    }

    .redes-footer{
        width: 100%;
        justify-content: space-around;
    }

    .footer-mailchimp{
        width: 90%;
        margin-inline: auto;
    }
    .footer-bottom{
        width: 120%;
        flex-direction: column;
        align-items: center;
    }

    .footer-credits{
        text-align: center;
        margin-bottom: 2rem;
    }
}
