.main-paypal{
    background-color: var(--primario) !important;
}

.contenido-dona{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card-paypal{
    margin-top: 5rem;
    background-color: var(--blanco);
    border-radius: 2rem;
    width: 25%;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-dona-paypal{
    font-size: 2.6rem;
    font-family: 'Lato';
    color: var(--g-200);
    font-weight: 600;
    padding: 1.5rem 6rem;
    border-radius: 1rem;
    background: var(--secundario);
    border: none;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
}

.btn-dona-paypal:hover{
    transform: scale(1.1);
    background-color: var(--s-600);
    box-shadow: 2px 3px 5px #21647a76;
}

.card-paypal img{
    width: 30%;
    margin-bottom: 4rem;
}

.dona-bottom{
    margin-bottom: 20rem;
    margin-top: 20rem;
    display: flex;
    flex-direction: row;
    width:100%;
    padding-left: 10rem;
    padding-right: 10rem;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
}

.frase{
    width: 40%;
}

.frase h4{
    font-weight: 400;
}

.autor-frase{
    margin-top: 2rem;
    font-size: 2.4rem;
    font-weight: 600;
}

.descripcion-dona{
    width: 35%;
    font-size: 2rem;
}

.img-dona{
    width: 30%;
    height: 30rem;
    background-image: url('../assets/img_dona.png');
    background-size: cover;
    background-position: center;
    border-radius: 2rem;
}


@media only screen and (max-width: 768px) {
    .card-paypal{
        width: 40%;
    }

    .descripcion-dona{
        width: 60%;
    }
}


@media only screen and (max-width: 576px) {
    .card-paypal{
        width: 60%;
    }

    .dona-bottom{
        flex-direction: column;
        align-items: center;
    }

    .frase{
        width: 90%;
    }

    .descripcion-dona{
        width: 80%;
    }
}