.cont-modal-taller{
    border-radius: 4rem;
    background-color: var(--g-900);
    display: flex;
    flex-direction: column;
    width: 60rem;
    z-index: 100;
    padding: 2rem 2rem;
    align-items: center;
}

.img-taller{
    width: 20rem;
    height: 20rem;
    border-radius: 25rem;
    background-position: center;
    background-size: cover;
}

.modal-taller-top{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.modal-taller-top .cont-taller{
    width: 23rem;
}

.taller-info-principal{
    width: 50%;
}

.etiquetas-taller{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.etiqueta-taller{
    border-radius: 25rem;
    border: 0.2rem solid var(--p-300);
    color: var(--p-300);
    font-weight: 600;
    padding: 0.3rem 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.etiqueta-taller p{
    font-size: 1.4rem;
}

.taller-modalidad{
    margin-bottom: 2rem;
}

.taller-modalidad p{
    color: var(--negro);
}

.taller-descripcion{
    margin-bottom: 2rem;
}


.etiqueta{
    margin-right: 1rem;
}
.modal-taller-bottom{
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.detalle-taller{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titulo-detalle-taller{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titulo-detalle-taller i{
    font-size: 2rem;
    color: var(--g-200);
    margin-right: 1rem;
}

.btn-registro-taller{
    font-size: 2rem;
    color: var(--blanco);
    background-color: var(--primario);
    border-radius: 1rem;
    border: none;
    padding: 1rem;
    width: 40%;
    font-family: Lato;
    margin-bottom: 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.btn-registro-taller:hover{
    background-color: var(--p-100);
    box-shadow: 2px 5px 5px #16455556;
    transform: scale(1.05);
}

@media only screen and (max-width: 576px) {
    .cont-modal-taller{
        width: 40rem;
    }

    .img-taller{
        display: none;
    }

    .taller-info-principal{
        width: 100%;
    }

    .modal-taller-bottom{
        flex-direction: column;
    }

    .detalle-taller{
        margin-bottom: 2rem;
    }
}