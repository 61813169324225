.cont-info-idea{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.cont-idea{
    align-items: flex-start !important;
}

.cont-info-idea h3{
    margin-bottom: 2rem;
}

.redes-idea{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 4rem;
}

.redes-idea i{
    font-size: 4em;
    text-decoration: none;
    color: var(--primario);
}

.text-idea{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.texto-global-thinking{
    margin-top: 6rem;
    text-align: center;
}
.img-global-thinking{
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .cont-idea{
        align-items: center !important;
    }

    .cont-info-idea{
        margin-top: 10rem;
        width: 80%
    }
}