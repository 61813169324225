.hero-proyectos{
    background-position: center;
    background-size: cover;
}

.btns-secciones{
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-around;
}

.btn-seccion{
    border: none;
    background-color: var(--secundario);
    font-family: Lato;
    font-size: 2.4rem;
    border-radius: 25rem;
    padding: 1rem 3rem;
    color: var(--primario);
    font-weight: 600;
    cursor: pointer;
}


.btn-seccion:hover{
    animation: btn_white_fade 0.1s ease-in-out forwards;
}

.dark-overlay{
    background: rgba(0, 0, 0, 0.5);
    padding: 10rem 0rem;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    backdrop-filter: contrast(70%);
}

.cultura-overlay{
    background: #79742d6d;
}

.arte-overlay{
    background: #f460604c;
}

.urbanismo-overlay{
    background: #6876836b;
}

.sustentabilidad-overlay{
    background: #75b28d6f;
}

.cultura{
    background-size: cover;
    background-position: center; 
}

.arte{
    background-size: cover;
    background-position: center; 
}
.urbanismo{
    background-size: cover;
    background-position: center; 
}
.sustentabilidad{
    background-size: cover;
    background-position: center; 
}

.descripcion-proceso{
    width: 50%;
    font-size: 2rem;
}

.proyectos-galeria{
    width: 100%;
    padding: 10rem 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
}


.btn-slider{
    position: absolute;
    width: 10rem;
    height: 10rem;
    border-radius: 25rem;
    border: none;
    margin-top: 7.5rem;
    margin-right: 10rem;
    background-color: var(--p-300);
    cursor: pointer;
}

.swiper-proyectos {
    width: 100%;
    display: flex;
  }

.swiper-proyectos a{
    text-decoration: none;
}

.proyecto-cont{
    width: 25rem !important;
    height: 25rem !important;
    border-radius: 2rem;
    background-position: center;
    background-size: cover;
    margin-left: 2rem;
    margin-right: 2rem;
}

.swiper-proyectos .swiper-button-next, .swiper-proyectos .swiper-button-prev{
    width: 10rem !important;
    height: 10rem !important;
    border-radius: 25rem;
    background-color: var(--p-300);
    color: var(--blanco) !important;
    margin-top: -5rem !important;
}

.swiper-slide:hover .descripcion-proceso-ind{
    display: flex;
    animation: fade_in 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.swiper-slide:hover .nombre-proceso-ind{
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fade_in 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}


.swiper-slide:hover .proyecto-overlay{
    animation: color_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.swiper-slide:hover .proyecto-overlay.cultura-proyecto{
    animation: yellow_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.swiper-slide:hover .proyecto-overlay.cultura-proyecto p{
    color: var(--negro);
}
.swiper-slide:hover .proyecto-overlay.cultura-proyecto h3{
    color: var(--negro);
}

.swiper-slide:hover .proyecto-overlay.arte-proyecto{
    animation: red_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.swiper-slide:hover .proyecto-overlay.urbanismo-proyecto{
    animation: gray_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
.swiper-slide:hover .proyecto-overlay.sustentabilidad-proyecto{
    animation: green_transform 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
.swiper-slide:hover{
    cursor: pointer;
}

.swiper-proyectos .swiper-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.slide-proyecto{
    background-size: cover;
    background-position: center;
}
.img-principal-proyecto{
    width: 484px;
}


@keyframes btn_white_fade{
    0%{background-color: var(--secundario); transform: scale(1);}
    100%{background-color: var(--blanco); transform: scale(1.1);}
}

@keyframes color_transform{
    0%{
        background: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: var(--primario)
    }
}

@keyframes yellow_transform{
    0%{
        background: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: var(--secundario)
    }
}

@keyframes red_transform{
    0%{
        background: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: #F46060;
    }
}

@keyframes gray_transform{
    0%{
        background: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: #687683;
    }
}

@keyframes green_transform{
    0%{
        background: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: #75B28D;
    }
}

.icono-pag-admin{
    color: #768D95 !important;
}


@media only screen and (max-width: 768px) {
    .btns-secciones{
        flex-wrap: wrap;
        width: 90%;
    }

    .btn-seccion{
        margin-bottom: 2rem;
    }

    .descripcion-proceso{
        width: 80%;
    }
}