
.hero-huella{
    background-size: cover;
    background-position: center;
}

.testimonios{
    width: 100%;
    display: flex;
    margin: 10rem 0;
    flex-direction: column;
    align-items: center;
}

.swiper-testimonios{
    padding: 10rem 0 !important;
    height: 48rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.cont-testimonio{
    background-image: url('../assets/blob_testimonio.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor:default !important;
}

.cont-testimonio:hover .img-testimonial{
    animation: heartbeat 0.3s ease-in-out forwards;
}


.nombre-testimonial{
    font-weight: 500;
    width: 90%;
}

.detalles-testimonial{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 8rem;
    margin-left: 4rem;
}

.texto-testimonio{
    font-size: 1.6rem;
    text-align: center;
    width: 38%;
    margin-top: 2rem;
    margin-left: -3rem;
}

.img-testimonial{
    width: 10rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    border-radius: 25rem;
    position: absolute;
    margin-top: -3rem;
    margin-left: 10rem;
    z-index: 100;
}


.swiper-testimonios .swiper-button-next, .swiper-testimonios .swiper-button-prev{
    width: 15rem !important;
    height: 15rem !important;
    color: var(--primario) !important;
    top: 58.5rem !important;
    cursor: pointer;
}


.swiper-testimonios .swiper-button-next{
    margin-right: 40%;
}

.swiper-testimonios .swiper-button-prev{
    margin-left: 40%;
}

.swiper-testimonios .swiper-pagination-bullets{
    margin-bottom: 3rem;
}

.swiper-testimonios .swiper-pagination-bullet-active{
    
    background-color: var(--primario)!important;
}

.swiper-button-next:after{
    font-weight: 900 !important;
}

.swiper-button-prev:after{
    font-weight: 900 !important;
}

.swiper-notas {
    width: 100%;
    display: flex;
  }

  .swiper-notas a{
    text-decoration: none;
}

.swiper-notas .swiper-button-next, .swiper-notas .swiper-button-prev{
    width: 10rem !important;
    height: 10rem !important;
    border-radius: 25rem;
    background-color: var(--p-300);
    color: var(--blanco) !important;
    margin-top: -5rem !important;
}

.swiper-notas .swiper-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.notas-medio{
    width: 100%;
    display: flex;
    margin: 20rem 0;
    flex-direction: column;
    align-items: center;
}

.cont-nota-medio{
    width: 20rem;
    background-color: var(--blanco);
    border-radius: 2rem;
}

.cont-nota-medio:hover .franja-nota{
    background-color: var(--secundario);
}

.franja-nota{
    background-color: var(--primario);
    width: 100%;
    height: 1rem;
    border-radius:  2rem 2rem 0 0;
}

.detalles-nota{
    padding: 2rem 4rem;
}

.fecha-nota{
    font-size: 1.6rem;
    color: var(--g-300);
}

.nombre-nota{
    margin-bottom: 2rem;
}

.separador-nota{
    height: 0.1rem;
    width: 100%;
    background-color: var(--g-800);
}

.fuente-nota{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icono-nota{
    font-size: 2rem;
    color: var(--g-400);
    margin-right: 1rem;
}

.nombre-fuente{
    color: var(--g-400);
}

.section-title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 5rem;
}

.section-description{
    width: 40%;
}

.galeria-aliados{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20rem;
    margin-top: 5rem;
}


.img-alianza{
    margin-inline: 4rem;
    width: 20rem;
    margin-bottom: 4rem;
}

@media only screen and (max-width: 1200px) {
    .detalles-testimonial{
        margin-left: 3rem;
    }
    .texto-testimonio{
        width: 45%;
        margin-left: -2rem;
    }
    .swiper-testimonios .swiper-button-next, .swiper-testimonios .swiper-button-prev{
        width: 8rem !important;
    }
}

@media only screen and (max-width: 992px) {
    .texto-testimonio{
        width: 45%;
        margin-left: -5rem;
    }
    
}

@media only screen and (max-width: 768px) {
    .texto-testimonio{
        width: 35%;
        margin-left: -5rem;
    }

    .swiper-testimonios .swiper-button-next, .swiper-testimonios .swiper-button-prev{
        width: 4rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .swiper-testimonios .swiper-button-next, .swiper-testimonios .swiper-button-prev{
        width: 0rem !important;
    }
    .texto-testimonio{
        width: 45%;
        margin-left: -3rem;
    }

    .swiper-testimonios .swiper-button-next{
        margin-right: 30%;
    }

    .swiper-testimonios .swiper-button-prev{
        margin-left: 30%;
    }
}
@media only screen and (max-width: 450px) {
    .swiper-testimonios .swiper-button-next, .swiper-testimonios .swiper-button-prev{
        width: 0rem !important;
    }

    .texto-testimonio{
        width: 60%;
        margin-left: -3rem;
    }
}