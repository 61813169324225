.modal-bg{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.738);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

.cont-modal-miembro{
    border-radius: 4rem;
    background-color: var(--g-900);
    display: flex;
    flex-direction: row;
    width: 90rem;
    z-index: 100;
}

.modal-contenido{
    padding: 4rem 6rem;
    width: 60%;
}

.imagen-completa-miembro{
    width: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4rem 0 0 4rem;
}


.nombre-miembro-modal{
    margin-bottom: 2rem;
}

.close-btn-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.btn-cerrar-modal{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primario);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}
.descripcion-miembro-modal{
    font-size:1.6rem;
    margin-bottom: 4rem;
    text-align: justify;
}
.btn-cerrar{
    width: 6%;
    cursor: pointer;
}

.btn-cerrar:hover{
   transform: scale(1.2);
}


@media only screen and (max-width: 768px) {
    .cont-modal-miembro{
        width: 65rem;
    }

}

@media only screen and (max-width: 576px) {
    .cont-modal-miembro{
        width: 80%;
    }
    .imagen-completa-miembro{
        display: none;
    }

    .modal-contenido{
        width: 80%;
    }

    .nombre-miembro-modal{
        text-align: center;
        font-size: 3.2rem;
    }
}