.detalles-principal{
    margin-top: 15rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20rem;
}

.img-principal-svg{
    width: 40rem;
}

.img-principal-proyecto{
    width: 135%;
}

.info-principal{
    margin-top: 2rem;
    width: 50%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.nombre-proyecto{
    text-align: start;
    margin-bottom: 1rem;
    width: 100%;
}

.categorias-proyecto{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 4rem;
}

.categoria{
    background-color: #25728b8d;
    border: 0.3rem solid var(--p-300);
    padding: 0.5rem 2rem;
    border-radius: 25rem;
    color: var(--negro);
    margin-right: 1rem;
}

.descripcion-proyecto{
    color: var(--g-400);
    width: 100%;
    margin-bottom: 1rem;
}

.tag-estatus{
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 25rem;
    width: 10rem;
    color: var(--g-300);
    margin-bottom: 4rem;
    margin-top: 1rem;
}

.e-activo{
    background-color: #b5f06770;
    border: 0.3rem solid #A1DB85;
}

.e-pasado{
    background-color: #f0a96764;
    border: 0.3rem solid #EE9F57;
}

.e-proximo{
    background-color: #f0e96768;
    border: 0.3rem solid #F0E967;
}

.cont-botones-proyecto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.cont-botones-proyecto a{
    width: 100%;
}

.btn-proyecto-externo{
    width: 100%;
    font-family: Lato;
    background-color: var(--secundario);
    color: var(--negro);
    border: none;
    cursor: pointer;
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
}

.btn-proyecto-externo:hover{
    background-color: var(--s-600) !important;
    box-shadow: 2px 5px 5px #8f863956;
    transform: scale(1.05);
}

.btn-colabora{
    width: 100%;
    font-size: 1.8rem;
    border: none;
    background-color: var(--primario);
    color: var(--blanco);
    font-weight: 600;
    padding: 1.5rem 3rem;
    border-radius: 1rem;
    font-family: Lato;
    cursor: pointer;
}

.btn-colabora:hover{
    background-color: var(--p-100);
    box-shadow: 2px 5px 5px #16455556;
    transform: scale(1.05);
}


.detalles-secundario{
    padding: 0 20rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 10rem;
}


.cont-proposito{
    margin-bottom: 4rem;
}

.cont-detalles-sec{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.objetivos{
    width: 40%;
}

.procesos{
    width: 50%;
    text-align: justify;
    
}

.procesos-texto{
    font-size: 1.8rem;
    white-space: pre-line;
}


.h-proyecto{
    margin-bottom: 2rem;
    text-align: center;
}

.lista-objetivos{
    list-style: none;
}

.objetivo-ind{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
}

.objetivo-ind svg{
    margin-right: 1rem;
}

.cont-proposito{
    text-align: center;
}
.galeria-proyecto{
    margin-bottom: 10rem;
}

.swiper-galeria-proyecto{
    height: 40rem;
    width: 40rem;
}

.slide-proyecto{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: var(--primario);
}

.swiper-galeria-proyecto .swiper-button-next, .swiper-galeria-proyecto .swiper-button-prev{
    color: var(--s-700) !important;
}

.icono-full{
    font-size: 3rem;
    z-index: 100;
}

@media only screen and (max-width: 992px) {
    .detalles-principal{
        padding: 0 15rem;
    }

    .detalles-secundario{
        padding: 0 15rem;
    }
}

@media only screen and (max-width: 880px) {
    .detalles-principal{
        padding: 0 10rem;
    }

    .detalles-secundario{
        padding: 0 10rem;
    }
}

@media only screen and (max-width: 768px) {
    .detalles-principal{
        margin-top: 20rem;
        flex-direction: column;
        justify-content: start;
    }

    .nombre-proyecto{
        text-align: center;
    }

    .img-principal-svg{
        width: 30rem;
        height: auto;
    }

    .info-principal{
        margin-top: 4rem;
        width: 100%;
        align-items: center;
    }

    .categorias-proyecto{
        margin-bottom: 8rem;
    }
}

@media only screen and (max-width: 576px) {

    .detalles-principal{
        padding: 0 5rem;
    }

    .detalles-secundario{
        padding: 0 5rem;
    }
    .cont-detalles-sec{
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .objetivos{
        margin-bottom: 4rem;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .procesos{
        width: 100%;
    }

    .cont-proposito{
        margin-bottom: 4rem;
        text-align: justify;
    }
}