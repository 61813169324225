*{
    margin: 0;
    padding: 0;
}
html{
    font-size: 10px;
    font-family: 'Lato', sans-serif;
    background-color: #f6f6f6;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
.link-decoration{
	text-decoration: none;
}

.main-landing{
	position: relative;
}

p{
    font-size: 1.8rem;
}

h1{
    font-size: 6rem;
}

h2{
    font-size: 4.2rem;
}

h3{
    font-size: 3rem;
	
}

h4{
	font-size: 2.4rem;
}

.blanco{
    color: var(--blanco)
}

.negro{
    color: var(--g-200)
}

.verde{
    color: var(--primario)
}

.amarillo{
    color: var(--secundario)
}

.bg-verde{
	background-color: var(--primario);
}

.titulo-header{
    width: 100%;
    margin-top: 30rem;
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
}
.titulo-header h1{
    width: 70%;
}
:root{
    --primario: #164453;
    --secundario: #f0e967;
    --blanco: #f0f0ee;
    --negro: #1b1b18;

    --p-900: #d7edf4;
	--p-800: #aedbea;
	--p-700: #86c9df;
	--p-600: #5eb7d4;
	--p-500: #35a5ca;
	--p-400: #2b84a1;
	--p-300: #206379;
	--p-200: #164453;
	--p-100: #0b2128;
	--s-900: #faf8d1;
	--s-800: #f6f2a2;
	--s-700: #f0e967;
	--s-600: #ede445;
	--s-500: #e8dd17;
	--s-400: #bab112;
	--s-300: #8b850e;
	--s-200: #5d5909;
	--s-100: #2e2c05;
	--g-900: #f0f0ee;
	--g-800: #cfcfc9;
	--g-700: #b7b7ae;
	--g-600: #9f9f93;
	--g-500: #878778;
	--g-400: #6c6c60;
	--g-300: #515148;
	--g-200: #363630;
	--g-100: #1b1b18;

}

.titulo-hero{
	margin-top: 20rem;
}

.bold{
	font-weight: 800;
}

main{
	box-sizing: border-box;
	overflow-x: hidden;
}

body{
	overflow-x: hidden;
}

.descripcion-seccion{
	font-weight: 400;
    color: var(--p-200);
	margin-top: 2rem;
    margin-bottom: 10rem;
	text-align: center;
	width: 45%;
}

svg {
    pointer-events: none;
}

path{
    pointer-events: auto;
}

.p-300{
	color: var(--p-300);
	font-weight: 600;
}


@media only screen and (max-width: 1400px) {
	html{
		font-size: 9px;
	}
}

@media only screen and (max-width: 1200px) {
	html{
		font-size: 8px;
	}
}

@media only screen and (max-width: 992px) {
	html{
		font-size: 7px;
	}

	
}


@media only screen and (max-width: 768px) {
	html{
		font-size: 8px;
	}
}


@media only screen and (max-width: 576px) {
	html{
		font-size: 7.5px;
	}
}