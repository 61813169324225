.header-aviso{
    margin-top: 25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 10rem;
}

.img-aviso{
    width: 15%;
    margin-bottom: 4rem;
}

.cont-aviso{
    width: 100%;
    padding: 0 30rem;
    text-align: justify;
    box-sizing: border-box;
    margin-bottom: 20rem;
}

.aviso-texto{
    line-height: 4rem;
    font-size: 2rem;
}

@media only screen and (max-width: 992px) {
    .img-aviso{
        width: 20%;
    }
    .cont-aviso{
        padding: 0 20rem;
    }
}

@media only screen and (max-width: 768px) {
    .titulo-aviso{
        width: 80%;
    }
    .cont-aviso{
        padding: 0 15rem;
    }
    .img-aviso{
        width: 25%;
    }
}

@media only screen and (max-width: 576px) {
    .cont-aviso{
        padding: 0 10rem;
    }
    .img-aviso{
        width: 40%;
    }
}