.login-main{
    background-image: url('../assets/rhesidentt_pattern.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.login-cont{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}


.logo-login{
    width: 15%;
}

.card-login{
    margin-top: 4rem;
    width: 40rem;
    background-color: var(--blanco);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
}

.card-login h3{
    margin-bottom: 4rem;
}

.subt-forgot{
    width: 80%;
    margin-bottom: 4rem;
}

.btn-login{
    margin-top: 4rem;
    border-radius: 25rem;
    font-family: Lato;
    background-color: var(--secundario);
    border: none;
    padding: 1.5rem;
    font-size: 2rem;
    color: var(--negro);
    font-weight: 600;
    cursor: pointer;
}

.btn-login:hover{
    transform: scale(1.05);
    box-shadow: 2px 2px 5px rgba(118, 121, 51, 0.386);
}

.login-form{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.login-form label{
    text-align: start;
}


.btn-forgot{
    border: none;
    background-color: rgba(255, 255, 255, 0);
    text-decoration: underline;
    margin-top: 2rem;
    font-family: Lato;
    cursor: pointer;
}

.login-error{
    display: flex;
    flex-direction: row;
    color: #E85E5E;
    text-align: left;
    align-items: center;
}

.login-success{
    display: flex;
    flex-direction: row;
    color: var(--primario);
    text-align: left;
    align-items: center;
}

.error-icon{
    font-size: 2rem;
    margin-right: 1rem;
}