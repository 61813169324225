.header-principal{
    position: absolute;
    width: 100%;
    display: flex;
    padding: 2rem 8rem;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    box-sizing: border-box;
    z-index: 100;
}

.cont-logo{
    width: 15%;
}
.logo-header{
    max-width: 20rem;
}

.logo-filler{
    max-width: 12rem;
    display: none;
}

.header-filler{
    width: 15%;
}

.nav-principal{
    z-index: 10;
    width: 65rem;
    font-size: 1.8rem;
}

.nav-submenu-cont{
    z-index: -1;
    position: absolute;
    top: -35rem;
    color: var(--primario);
    background-color: var(--secundario);
    padding: 2rem 0;
    border-radius: 2rem;
}

.submenu-elements{
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
}

.submenu-elements a{
    padding: 0;
    padding: 0.5rem 1.5rem;
    color: var(--primario) !important;
    font-weight: 400;
}

.submenu-elements a:hover{
    background-color: var(--primario);
    color: var(--blanco) !important;
}

.nav-list{
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    list-style: none;
    font-weight: bold;
    justify-content: space-between;
}
.nav-element{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.arrow-sub.light{
    margin-top: 0.5rem;
    fill: var(--secundario);
}

.arrow-sub.dark{
    margin-top: 0.5rem;
    fill: var(--primario);
}


.nav-element:hover {
    cursor: pointer;
}
.nav-element:hover .nav-indicator.light{
    animation-name: submenu_indicator;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    background: var(--secundario);
}

.nav-element:hover .nav-indicator.dark{
    animation-name: submenu_indicator;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    background: var(--primario);
}

.active-light{
    background: var(--secundario) !important;
}

.active-dark{
    background: var(--primario) !important;
}

.nav-submenu:hover .nav-submenu-cont{
    animation: submenu 0.3s ease-in-out 1 ;
    animation-fill-mode: forwards;
}

.nav-submenu:hover .arrow-sub{
    animation: arrowflip 0.5s ease-in-out 1 ;
    animation-fill-mode: forwards;
    fill: var(--primario) !important;
}

.nav-submenu:hover .submenu-title{
    color: var(--primario)
}

.nav-submenu:hover .nav-indicator.light{
    background: var(--primario);
}


.nav-list a{
    text-decoration: none;
}

.icono-menu{
    font-size: 4rem;
    color: var(--secundario);
    display: none;
    margin-left: 4rem;
    cursor: pointer;
}


.nav-indicator{
    margin-inline: auto;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    width: 80%;
    height: 0.3rem;
    border-radius: 25rem;
    background: transparent;
}


@keyframes submenu{
    0% {
        top: -35rem;
    }
    
    100% {
        top: 5rem;
    }
}

@keyframes submenu_reverse{
    0% {
        top: 3rem;
    }
    
    100% {
        top: -30rem;
    }
}
@keyframes arrowflip{
    0% { transform: rotate(0deg); }
    
    100% { transform: rotate(180deg); }
}


@keyframes submenu_indicator{
    0% { 
            transform: translateY(-1rem);
            opacity: 0; 
        }
    
    100% { transform: translateY(0.1rem);
        opacity: 1;  }
}


@media only screen and (max-width: 992px){
    .logo-header{
        width: 85%;
    }
}

@media only screen and (max-width: 768px){
    
    .logo-header{
        width: 110%;
        margin-left: -2rem;
    }
    .nav-principal{
        display: none;
    }

    .icono-menu{
        display: flex;
    }
}


@media only screen and (max-width: 576px){
    .logo-header{
        width: 180%;
    }
}