.body-admin{
    background-color: #F5F4F7 !important;
    width: 100%;
}

.main-admin{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-right: 10rem;
    margin-left: 18%;
    padding: 0 10rem;

}

.header-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20vh;
    box-sizing: border-box;
}

.cont-bienvenida{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cont-bienvenida p{
    font-weight: 600;
}

.icono-pagina{
    font-size: 6rem;
    color: #768D95;
    margin-right: 1rem;
}

.icono-proyectos{
    width: 6rem;
    fill: #768D95;
}

.cont-accesos-directos{
    display: flex;
    flex-direction: row;
}

.acceso-directo{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
}

.acceso-directo i{
    font-size: 1.4rem;
    margin-right: 1rem;
}

.panel-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.panel-bottom-2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}

.card-contenido-panel{
    border-radius: 2.5rem;
    background-color: #FDFDFD;
    padding: 2rem 4rem;
}

.header-card-contenido{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
}

.vertical-indicator{
    width: 1rem;
    border-radius: 0.2rem;
    background: var(--primario);
    height: 3rem;
    margin-right: 1rem;
}

.vertical-indicator-light{
    width: 1rem;
    border-radius: 0.2rem;
    background: var(--blanco);
    height: 3rem;
    margin-right: 1rem;
}

.horizontal-indicator{
    width: 10rem;
    border-radius: 0.2rem;
    background: var(--primario);
    height: 1.5rem;
    margin-right: 1rem;
}
.card-recurso{
    width: 80%;
    margin-bottom: 2rem;
}


.layout1-panel-top{
    width: 100%;
    display: flex;
    flex-direction: row;
   justify-content: flex-end;
}

.card-estadisticas{
    width: 60%;
}


.btn-agregar-panel{
    width: 30%;
    background-color: var(--primario);
    padding: 2rem;
    border-radius: 2.5rem;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 2rem;
    text-decoration: none;
    height: 18rem;
}

.btn-agregar-panel:hover{ 
    transform: scale(1.05);
    background-color: var(--p-100);
    box-shadow: 2px 3px 5px #21647a76;
}


.icono-agregar{
    margin-left: 35%;
}

.card-cont-tabla{
    margin-top: 2rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10rem;
}

.card-cont-items{
    width: 80%;
    box-sizing: border-box;
}

/*--------- Overwrites Datagrid default styles -----------------*/
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    color: var(--negro);
    font-weight: 700 !important;
    font-size: 1.6rem;
}

.css-zp70nb-MuiDataGrid-root .MuiDataGrid-cell{
    border-bottom: 2px solid rgba(255, 255, 255, 0) !important;
}

.MuiMenuItem-root{
    font-size: 1.4rem !important;
    font-family: Lato;
}

.MuiTablePagination-toolbar p{
    font-size: 1.4rem !important;
    font-family: Lato;
}

.MuiTablePagination-toolbar{
    font-size: 1.4rem !important;
    font-family: Lato;
}
/*----------------------------------------------------------------*/


.icono-accion-tabla{
    font-size: 2rem;
    cursor: pointer;
}

.icono-accion-tabla:hover{
    transform: scale(1.1);
}

.icono-tabla-habilitar{
    color: #5F9779;
}
.icono-tabla-deshabilitar{
    color: var(--g-600);
}

.icono-tabla-ir{
    color: #206379;
}

.icono-tabla-editar{
    color: var(--g-300);
}

.icono-tabla-eliminar{
    color: #E85E5E;
}

.admin-form-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-form{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    text-align: center;
    margin-bottom: 4rem;
}


.ilustracion-form{
    margin-right: 2rem;
    width: 25rem;
}

.cont-items-panel{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}

.cont-recurso{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom:4rem;
}

.titulo-recurso{
    text-align: start;
    margin-bottom: 2rem;
}

.disable-popup{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.disable-popup i{
    margin-right: 1rem;
}